@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.navHeader ul {
  justify-content: center;
  list-style: none;
}
.navHeader ul li {
  padding: 30px 70px;
  padding-left: 35px;
  display: inline-flex;
}
.navHeader ul li a {
  text-decoration: none;
  font-size: 18px;
  color: #2e364b;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
@media only screen and (max-width: 769px) {
  .navHeader ul li a {
    font-size: 14px;
    text-align: center;
    justify-content: center;
  }
}
@media (max-width: 761px) {
  .navHeader .col-12 {
    /* justify-content: center; */
    display: none;
  }
}
