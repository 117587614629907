.partnersHeader {
  margin-top: 4rem;
}
.partnersHeader h3 {
  font-weight: bolder;
  font-size: 2.5em;
}
.partnersHeader p {
  margin-bottom: 4rem;
  font-size: 23px;
  opacity: 0.8;
}
.partnerRepeat {
  width: 3rem;
  height: 9rem;
  padding-left: 1rem;
  float: right;
}
.partnership {
  opacity: 0.8;
  font-weight: 700;
  font-size: 15px;
}
.smartbase{
  width: 80%;
  height: 150px;
}
.ablsoft {
  width: 80%;
}
@media (max-width: 950px){
.ablsoft{
  margin-bottom: 4rem;
}
  }