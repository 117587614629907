.dialog-main-div{
  position: fixed;
  top: 51rem;
  right: 3rem;
  z-index: 15 !important; 
}
.MuiDialog-paperWidthSm {
  max-width: 562px;
}
.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 7px 16px !important;
  height: 170px;
  width: 23rem !important;
}
.MuiInputBase-input {
  font: inherit;
  color: black !important;
  width: 33rem !important;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms !important;
  -webkit-tap-highlight-color: transparent;
}
.MuiButton-label:focus{
  box-shadow: none !important;
  outline: none !important;
}
.MuiDialog-scrollPaper {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}
.MuiDialog-container {
  height: 155% !important;
  outline: 0;
}
.iconButton{
  background: url('../images/chatIcon2.png');
 
}
@media only screen and (max-width: 600px)  {
  .dialog-main-div{
    position: fixed;
    top: 31rem !important;
    right: 1rem;
    padding-left: 25px !important;

  }
  .iconButton{
    transform: scale(0.5);
  }
  .MuiDialog-paperWidthSm {
    max-width: 600px;
    bottom: 75px !important;
}
  .MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 1px 24px !important;
    overflow-y: none !; 
    -webkit-overflow-scrolling: touch;
    width: 260px!important;
}
.MuiInputBase-input {
  width: 230px !important;
  
}
.dialog-p {
  margin-left: 21px !important;
}
}
.MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 2px !important;
   overflow-y: none !; 
  -webkit-overflow-scrolling: hidden !important;
  
}