@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap");

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #2e364b;
}

